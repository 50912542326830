import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import FooterTwo from "../component/footer/FooterTwo";

const project = [
  {
    role: "Full Stack  Developer (React + Python)",
    company: "Whole FInance",
    website: "-",
    projectUrl: "https://wholefinance.com/",
    skills:
      "React , Redux , React Highcharts ,  Lodash , Tailwind CSS , Data Visualization & Charting , Stripe , Python3 , Django , ReportLab , Pandas , Seaborn , Matplotlib and Amplitude",
    description: (
      <ul>
        <li>
          Developed a web app using React + Redux that provides real-time
          financial market data. Integrated D3.js to display data in the form of
          charts.
        </li>
        <li>
          Worked on building an AI tool similar to chatGPT but for the financial
          market where users can ask any questions and select any of the
          displayed commands for displaying the cash flow, return over the
          years, and report of any stock company.
        </li>
        <li>
          Worked on integrating Stripe as a payment solution. Integrated both
          one-time solutions and recurring payment solutions.
        </li>
        <li>
          Worked on building dataframes using numpy and pandas by analysing
          stock data over the years and modifying the same as per the
          requirements . Also used seaborn and matplotlib for building charts in
          reports.
        </li>
      </ul>
    ),
  },
  {
    role: "Full Stack Developer (React + Python)",
    company: "Lariat Data Inc.",
    website: "https://lariatdata.com",
    skills:
      "React , Redux , Redux Saga , Formik , Yup , D3.js , Lodash , Tailwind CSS , Data Visualization & Charting , Python , Django and Pandas",
    description: (
      <ul>
        <li>
          Created highly performant dashboards and debugging tools to help track
          and improve data quality for data and ML pipelines.
        </li>
        <li>
          Redesigned the entire UI using new Figma designs that consist of
          graphs, charts, data tables, and various other components. Added unit
          tests, formatting, and lining tools.
        </li>
        <li>
          Architected re-usable patterns to use D3.js within the React
          framework.Performed componentization of data visualizations for use in
          other parts of the company.
        </li>
        <li>
          Implemented data analysis using Python and Pandas to measure our
          programs' performance over a broad range of metrics.
        </li>
      </ul>
    ),
  },
  {
    role: "Full Stack Developer",
    company: "Allegis - Cruise",
    website: "https://getcruise.com",
    skills:
      "Next js , Redux , Nest Js, Jest , Puppeteer , Typescript , Twilio SDK and Testing",
    description: (
      <ul>
        <li>
          Automated the entire system with integrational testing and reduced the
          flakiness to less than 10% for the whole system.
        </li>
        <li>
          Wrote unit and end-to-end tests using Jest to ensure code quality and
          reliability in accordance with best practices.
        </li>
        <li>
          Added functionality to add multiple users to call thus creating a
          conference by integrating Twilio api . Fixed critical production bugs
          by investigating the issues with twilio voice calls and fixing the
          same.
        </li>
      </ul>
    ),
  },
  {
    role: "Full-stack Developer",
    company: "Thes Kraft Heinz Company",
    website: "https://www.kraftheinzcompany.com",
    skills:
      "Laravel , Livewire , Tailwind CSS , Azure , Laravel SPark , Stripe Js ,and AWS Textract",
    description: (
      <ul>
        <li>
          Created various MVPs using Livewire and Tailwind CSS. Also built a
          CI/CD pipeline for the automated testing and deployment of the PHP app
          using Azure.
        </li>
        <li>
          Integrated Laravel Spark and Stripe to build a billing solution in one
          of the MVPs. Integrated the subscription module and one-time payment
          module using the same.
        </li>
        <li>
          Managed the environment using AWS services and created different
          environments based on needs, including development, testing, staging,
          and live.
        </li>
        <li>
          Maintained the code quality by writing unit and browser test scripts
          using Laravel Dusk and maintained a good coding standard. Created a
          POC to extract text from receipts in PDF documents or images using
          Amazon Textract.{" "}
        </li>
      </ul>
    ),
  },
  {
    role: "Senior Frontend Developer",
    company: "Urgentem  (now acquired by ICE)",
    website:
      "https://www.ice.com/data-services/sustainable-finance-data/climate-data",
    skills:
      "React , Redux , Redux Thunk , Exress Js , Redux Form , React Highcharts, Lodash , Data Visualization & Charting",
    description: (
      <ul>
        <li>
          Built the climate and ESG risk platform from the ground up using React
          and Express.js. Implemented complex algorithms for inference and
          climate prediction models.
        </li>
        <li>
          Assisted with graph integration for visually appealing data using
          Highcharts. Worked on writing complex logic for calculations and
          statistics.
        </li>
        <li>
          Oversaw the launch process and fixed live production bugs in a limited
          timeframe. Optimized the components for maximum performance across
          various devices and browsers.
        </li>
      </ul>
    ),
  },
  {
    role: "Senior Frontend Developer",
    company: "Agewell Online",
    website: "-",
    projectUrl: "https://www.agewell.online",
    skills:
      "React , Redux , Twilio API , Stripe , React-Stripe , Laravel and MySQL",
    description: (
      <ul>
        <li>
          Build a twilio call conferencig system where people can have a dailiy
          checkin for old age relatives . In case if all the attempts for
          checkin are failed an emergency message / call will be delievred to
          the concerned people
        </li>
        <li>
          Integrated Stripe in React + Laravel app to build payment methods
          (Ideal and SEPA Direct Debit)
        </li>
        <li>
          Built twilio services for incoming as well as ougoing calls . Fixed
          bugs by connecting with the twilio customer support and finding out
          the root cause of issues with the integrated twilio call conferencing
          system
        </li>
      </ul>
    ),
  },
  {
    role: "Software Engineer",
    company: "Geekyants",
    website: "https://geekyants.com",
    skills:
      "React , Redux , Redux Thunk , Mapbox ,  React Full Calendar , Storybook , Tailwind CSS & Express Js  ",
    projectUrl: "https://simbex.geekydev.com",
    description: (
      <ul>
        <li>
          Assisted in building a large-scale web application for buying retail
          sports equipment that caters to 1,000+ concurrent users. Created
          extensive test coverage for all new features, reducing customer
          complaints by 10%.
        </li>
        <li>
          Integrated Twilio for one of the call center-based applications that
          manage incoming and outgoing calls along with other key
          functionalities.
        </li>
        <li>
          Architected re-usable patterns to use D3.js within the React
          framework.Performed componentization of data visualizations for use in
          other parts of the company.
        </li>
      </ul>
    ),
  },
  {
    role: "Full Stack  Developer (React + Python)",
    company: "Generative Genius",
    website: "-",
    projectUrl: "https://agentgg.ai/",
    skills:
      "React , Redux , Tailwind CSS , Stripe , Python3 , Django , LLMs , Open AI , Gemini , Anthropic ,  Lite LLM , oauth , AWS SES , AWS ec2 , Dynamo DB",
    description: (
      <ul>
        <li>
          Designed and developed an AI-powered platform using React and
          Python(Django) enabling users to query multiple LLMs (GPT-4o, Gemini,
          Claude, Grok) in sequential or parallel modes, providing flexible
          response aggregation.
        </li>
        <li>
          Developed a sequential processing system, where AI responses feed into
          one another to generate a comprehensive, refined final answer,
          enhancing multi-AI collaboration.
        </li>
        <li>
          Built a tiered payment system using Stripe, offering free trials,
          monthly subscriptions, and additional token-based usage, mirroring
          enterprise-grade AI pricing models.
        </li>
        <li>
          Created a multi-user workspace allowing organizations to add users,
          allocate tokens, manage access permissions, and track usage analytics.
        </li>
        <li>
          Designed customizable user settings for fine-tuned AI interactions,
          including model preferences, conversation history, uploaded files, and
          API-based integrations for advanced users.
        </li>
        <li>
          Integrated Amazon DynamoDB, SES, and SNS, enabling efficient data
          storage, email notifications, and OTP authentication.
        </li>
      </ul>
    ),
  },
];
class PortfolioDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.getPortfolioDetails = this.getPortfolioDetails.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  getPortfolioDetails = () => {
    const pathName = window.location.pathname;

    if (pathName) {
      const pathArray = pathName.split("/")[2];
      switch (pathArray) {
        case "financial-ai-tool":
          return 0;
        case "sass-product":
          return 1;
        case "customer-portal":
          return 2;
        case "mvp-food-industry":
          return 3;
        case "emission-dashboard":
          return 4;
        case "elderly-app":
          return 5;
        case "training-tool":
          return 6;
        case "ai-llms":
          return 7;
        default:
          return 8;
      }
    }
  };

  render() {
    const index = this.getPortfolioDetails();
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Portfolio Details" />

        {/* Start Portfolio Details */}
        <div
          className="rn-portfolio-details ptb--120"
          style={{ background: "#191919" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="inner">
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <div
                            class="d-flex flex-row"
                            style={{ marginBottom: 12 }}
                          >
                            <div
                              class="font-weight-bold w-25"
                              style={{ marginRight: 12 }}
                            >
                              Role:{" "}
                            </div>
                            <div class="w-75"> {project[index]["role"]}</div>
                          </div>
                          <div
                            class="d-flex flex-row"
                            style={{ marginBottom: 12 }}
                          >
                            <div
                              class="font-weight-bold w-25"
                              style={{ marginRight: 12 }}
                            >
                              Company:{" "}
                            </div>
                            <div class="w-75"> {project[index]["company"]}</div>
                          </div>
                          <div
                            class="d-flex flex-row"
                            style={{ marginBottom: 12 }}
                          >
                            <div
                              class="font-weight-bold w-25"
                              style={{ marginRight: 12 }}
                            >
                              Company Website:{" "}
                            </div>
                            <a href={project[index]["website"]}>
                              {" "}
                              {project[index]["website"]}
                            </a>
                          </div>
                          <div
                            class="d-flex flex-row"
                            style={{ marginBottom: 12 }}
                          >
                            <div
                              class="font-weight-bold w-25"
                              style={{ marginRight: 12 }}
                            >
                              Tech:{" "}
                            </div>
                            <div class="w-75"> {project[index]["skills"]}</div>
                          </div>
                          {project[index]["projectUrl"] && (
                            <div
                              class="d-flex flex-row"
                              style={{ marginBottom: 12 }}
                            >
                              <div
                                class="font-weight-bold w-25"
                                style={{ marginRight: 12 }}
                              >
                                Project
                              </div>
                              <a
                                class="w-75"
                                href={project[index]["projectUrl"]}
                                style={{ color: "#823ffc" }}
                              >
                                {" "}
                                {project[index]["projectUrl"]}
                              </a>
                            </div>
                          )}
                          <div
                            class="d-flex flex-row"
                            style={{ marginBottom: 12 }}
                          >
                            <div
                              class="font-weight-bold w-25"
                              style={{ marginRight: 12 }}
                            >
                              Description :{" "}
                            </div>
                            <div class="w-75">
                              {project[index]["description"]}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default PortfolioDetails;
